<template>
    <div>
        <b-row align-h="center">
            <b-col cols="6">
                <p class="mb-0 h4" v-text="$t(locale.title)" />
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
                <span
                    role="button"
                    @click="$router.push({name: 'acountingCreateInvoices'})"
                >
                    <mdicon
                        :width="25"
                        :height="25"
                        class="text-secondaryColor"
                        name="plus-box"
                    />
                </span>
                <span role="button" @click="synInvoices">
                    <mdicon
                        :width="25"
                        :height="25"
                        class="text-secondaryColor"
                        name="refresh"
                    />
                </span>
            </b-col>
        </b-row>

        <InvoiceOverview />
    </div>
</template>

<script>
import InvoiceOverview from '@/@core/components/accountingData/invoices/invoicesOverview.vue';
export default {
    name: 'invoices',
    components: {
        InvoiceOverview,
    },

    data() {
        return {
            locale: {
                title: 'invoicesCard.invoiceOverview',
                registerPayment: 'invoicesCard.registerPayment',
                book: 'invoicesCard.book',
                inputPlaceholder: 'CustomersCard.inputPlaceholder',
                showPerPage: 'golfRelatedCard.showPerPage',
                tag: 'CustomersCard.tag',
                apply: 'golfRelatedCard.apply',
                action: 'golfRelatedCard.action',
            },
        };
    },
    methods: {
        synInvoices() {
        this.fetch = false
        this.$useJwt.customSync({URL: '/invoices', method: 'get'})
            .then(res => {
                this.fetch = true
                this.$emit('close-toast')
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data.message,
                    'CheckIcon',
                    'success'
                );
            })
            .catch(err => {
                this.loadingAction = false
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data[this.message],
                    'AlertTriangleIcon',
                    'danger'
                );
            })
        },
    }
};
</script>
