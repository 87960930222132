<template>
  <div>
    <div class="mt-2">
      <b-card>
        <!-- <Loading v-if="loadingState" /> -->
        <b-card-text>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-row align-h="center" align-v="center">
                <b-col cols="12" lg="6">
                  <div class="d-flex smallGap">
                    <b-button
                        v-for="(button, index) in categoryButton"
                        :key="index"
                        v-ripple.400
                        size="sm"
                        :class="
                        categoryValue === button.value ? 'custom-shadow' : ''
                      "
                        class="text-white"
                        @click="selectedStatus(button)"
                        :variant="button.variant"
                        v-text="$t(button.label)"
                    />
                  </div>
                </b-col>
                <b-col
                    cols="12"
                    lg="6"
                    class="justify-content-end mt-1 mt-lg-0 d-flex"
                >
                  <div class="d-flex bigGap w-75 align-items-center">
                    <b-form-input
                        :placeholder="$t(locale.inputPlaceholder)"
                        v-model="queryParams.search"
                    />
                    <div
                        v-ripple.400
                        v-b-modal.modal-customize
                        class="d-flex text-secondaryColor smallGap"
                        role="button"
                    >
                      <img src="@/assets/icon/dashboardAdd.png" width="20px"/>
                      <p class="mb-0" v-text="$t(locale.customize)"/>
                    </div>
                  </div>
                </b-col>

                <!-- Table -->

                <b-col cols="12" class="mt-2">
                  <div class="scrol-area table-responsive">
                    <div
                        v-if="loadingState"
                        class="text-center text-danger my-2"
                    >
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('Message.loading') }}...</strong>
                    </div>
                    <table v-else class="table b-table table-hover">
                      <thead class="">
                      <tr class="items-center">
                        <th class="text-left whitespace-nowrap p-2 md:p-0">
                            <span class="text-xs text-gray-400">
                              <b-form-checkbox
                                  v-model="selected"
                                  @change="performAllChecked"
                                  :value="checked"
                                  class="custom-control-primary my-auto"
                              />
                            </span>
                        </th>
                        <th
                            width="100"
                            v-for="(item, i) in invoiceTableHeader"
                            :key="i"
                            class="text-center whitespace-nowrap c-p-2 md:p-0"
                        >
                          <div
                              class="flex justify-between gap-5 items-center w-full"
                          >
                              <span class="text-xs text-gray-400">{{
                                  renderName(item)
                                }}</span>
                            <span class="flex flex-col">
                                <svg
                                    @click="sortColumn(item)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                                <svg
                                    @click="sortColumn(`-${item}`)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                          </div>
                        </th>
                        <th class="text-center whitespace-nowrap p-2 md:p-0">
                          <span class="text-xs text-gray-400">Action</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="data in items" :key="data.uuid">
                        <td>
                            <span>
                              <b-form-checkbox
                                  @change="performSingleChecked"
                                  v-model="data.checkbox"
                              />
                            </span>
                        </td>
                        <td v-for="(item, i) in invoiceTableHeader" :key="i">
                          <router-link
                              :to="{
                                name: 'acountingEditInvoices',
                                params: { id: data.uuid },
                              }"
                          >
                              <span
                                  class="whitespace-nowrap text-sm"
                                  v-if="['tags'].includes(item)"
                              >{{ data[item].join(",") }}</span
                              >

                            <span
                                v-else
                                class="whitespace-nowrap text-sm"

                            >
                              <span v-if="filterType.includes(queryParams.filter)"
                                    :class="{
                                'text-green-500': queryParams.filter === 'fullypaid',
                                'text-yellow-500': queryParams.filter === 'unbooked',
                                'text-blue-500': queryParams.filter === 'booked',
                                'text-gray-900': queryParams.filter === 'voided',
                                'text-danger': queryParams.filter === 'unpaid',
                              }"
                              >{{ data[item] }}</span>
                              <span v-else :class="{
                                    'text-green-500': data.fully_paid && !data.cancelled,
                                    'text-yellow-500': !data.booked && !data.cancelled,
                                    'text-blue-500': data.booked && !data.cancelled && new Date(data.due_date) >= new Date() && !data.fully_paid,
                                    'text-danger': data.booked && !data.cancelled && new Date(data.due_date) < new Date() && !data.fully_paid,
                                    'text-gray-900': data.cancelled
                                }"> {{ data[item] }}</span>

                            </span
                            >
                          </router-link>
                        </td>
                        <td>
                          <div
                              class="d-flex justify-content-between smallGap align-items-center px-2"
                          >
                              <span
                                  role="button"
                                  v-b-tooltip.hover
                                  :title="$t(locale.registerPayment)"
                                  v-if="
                                  data['booked'] === false ||
                                  data['fully_paid'] === true ||
                                  data['cancelled'] === true
                                "
                              >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    class="text-blue-400"
                                    name="credit-card-outline"
                                />
                              </span>

                            <span
                                role="button"
                                v-b-tooltip.hover
                                v-else
                                :title="$t(locale.registerPayment)"
                                @click="openModal(data, 'register')"
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    class="text-secondaryColor"
                                    name="credit-card-outline"
                                />
                              </span>

                            <span
                                role="button"
                                v-b-tooltip.hover
                                @click="openModal(data, 'invoice')"
                                :title="$t(locale.send)"
                            >
                                <feather-icon
                                    icon="SendIcon"
                                    class="text-secondaryColor"
                                    size="18"
                                />
                              </span>

                            <span
                                role="button"
                                v-b-tooltip.hover
                                :title="$t(locale.copy)"
                                @click="copyInvoiceData(data)"
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-secondaryColor"
                                    name="content-copy"
                                />
                              </span>

                            <span
                                v-if="
                                  data.scheduled_invoice_sending.length > 0 &&
                                  data.scheduled_invoice_sending.some(
                                    (item) => !item.handled
                                  )
                                "
                                role="button"
                                v-b-tooltip.hover
                                @click="openUpdateModal(data, 'sendout')"
                                :title="$t(locale.sendOut)"
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-success"
                                    name="calendar-clock"
                                />
                              </span>
                            <span
                                v-else
                                role="button"
                                v-b-tooltip.hover
                                @click="openModal(data, 'sendout')"
                                :title="$t(locale.sendOut)"
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-secondaryColor"
                                    name="calendar-clock"
                                />
                              </span>

                            <span
                                role="button"
                                v-b-tooltip.hover
                                @click="openRceiptForm(data)"
                                :title="$t(locale.sendReceipt)"
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-secondaryColor"
                                    name="receipt"
                                />
                              </span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-row
                      class="justify-content-center justify-content-lg-between"
                  >
                    <b-col cols="12" sm="6" class="d-flex align-items-end">
                      <div
                          class="d-flex bigGap justify-content-between align-items-center"
                      >
                        <p class="mb-0 h6" v-text="$t(locale.action)"/>
                        <div class="">
                          <b-form-select
                              id="perPageSelect"
                              size="sm"
                              inline
                              v-model="actionLable"
                          >
                            <b-form-select-option
                                v-for="(option, index) in actionButton"
                                :key="index"
                                :value="option.value"
                                v-text="$t(option.label)"
                            />
                          </b-form-select>
                        </div>

                        <b-button
                            size="sm"
                            variant="primary"
                            @click="applyMultiAction(actionLable)"
                            v-text="
                            loadingAction
                              ? $t('Message.loading')
                              : $t(locale.apply)
                          "
                        />
                      </div>
                    </b-col>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
                    >
                      <paginate
                          v-model="pagination.currentPage"
                          :page-count="pagination.totalProducts"
                          :click-handler="firePagination"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :page-class="'page-item c-mr-1 border-unset'"
                          prev-link-class="page-link c-mr-1 border-unset"
                          next-link-class="page-link c-mr-1 border-unset"
                          next-class="next-item"
                          prev-class="prev-item"
                          page-link-class="page-link c-mr-1 border-unset"
                          active-class="active c-mr-1 border-unset"
                          :container-class="'pagination  b-pagination'"
                      >
                      </paginate>
                      <!-- <b-pagination
                                                v-model="pagination.currentPage"
                                                :total-rows="10"
                                                :per-page="queryParams.limit"
                                                class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                                                prev-class="prev-item"
                                                next-class="next-item"
                                            >
                                                <template #prev-text>
                                                    <feather-icon
                                                        icon="ChevronLeftIcon"
                                                        size="18"
                                                    />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon
                                                        icon="ChevronRightIcon"
                                                        size="18"
                                                    />
                                                </template>
                                            </b-pagination> -->

                      <div
                          class="d-flex justify-content-between justify-content-sm-end smallGap align-items-center mt-1 w-100 order-1 order-sm-2"
                      >
                        <p
                            class="mb-0 d-none d-sm-block h6"
                            v-text="$t(locale.showPerPage)"
                        />
                        <div class="">
                          <b-form-select
                              v-model="queryParams.limit"
                              @change="switchLimit"
                              id="perPageSelect"
                              size="sm"
                              inline
                              :options="pageOptions"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </div>

    <Modal
        id="modal-registerPayment"
        :hideFooter="true"
        :title="$t(locale.registerPayment)"
    >
      <!-- :closeEvent="closeModal"
            :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': this.$t(locale.book),
                'modal-class': 'modal-primary',
            }"
            :okEvent="okEvent" -->
      <ValidationObserver ref="registerForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in registerPaymentForm"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label v-if="index === 1" class="h6 font-weight-bold">
              {{ $t(form.label) }} (<strong> {{ selectedData.currency }}</strong
            >)
              <span class="text-danger">*</span>
            </label>
            <label v-else class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="index === 0">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-if="index === 1">
              <b-form-input
                  type="number"
                  min="0"
                  step="0.1"
                  v-model="form.value"
                  :placeholder="$t(form.label)"
              />
            </template>

            <template v-if="index === 2">
              <v-select
                  class="w-full"
                  v-model="form.value"
                  label="number"
                  @option:selected="changeAccounts($event, form)"
                  :filterable="false"
                  :options="accountResults"
                  @search="searchAccounts"
              >
                <template slot="no-options"> Sök...</template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.number + " " + option.description }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.number }}
                  </div>
                </template>
              </v-select>
            </template>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-registerPayment')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="registerPaymentInvoice"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : 'Send'"
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-send-payment"
        :title="$t(locale.send)"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': this.$t(locale.book),
                'modal-class': 'modal-primary',
            }"
            :okEvent="okEvent" -->
      <ValidationObserver ref="sendForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in sendInvoice"
            :name="$t(form.label)"
            :key="index"
            v-show="form.visible"
        >
          <!-- <div class="flex flex-col">
                        <div>
                            <label class="h6 font-weight-bold">
                                {{ $t(form.label) }}
                                <span class="text-danger">*</span>
                            </label>

                            <v-select
                                v-if="Array.isArray(form.options)"
                                v-model="form.value"
                                :options="form.options"
                                @input="inputChange"
                                :placeholder="$t(form.label)"
                                class="resource-selector d-inline-block"
                            />
                        </div>

                         <div>
                            <label class="h6 font-weight-bold">
                                {{ $t(form.label) }}
                                <span class="text-danger">*</span>
                            </label>

                            <v-select
                                v-if="Array.isArray(form.options)"
                                v-model="form.value"
                                :options="form.options"
                                @input="inputChange"
                                :placeholder="$t(form.label)"
                                class="resource-selector d-inline-block"
                            />
                        </div>

                    </div> -->

          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <v-select
                v-if="Array.isArray(form.options)"
                v-model="form.value"
                :options="form.options"
                @input="inputChange"
                :placeholder="$t(form.label)"
                class="resource-selector d-inline-block"
            />
            <v-select
                v-else
                v-model="form.value"
                :options="form.selectOptions"
                :placeholder="$t(form.label)"
                class="resource-selector d-inline-block"
            />

            <small class="text-danger" v-text="errors[0]"/>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-send-payment')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="sendInvoicePayment"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : 'Send'"
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-sendOut"
        :title="$t(locale.sendOutSchedulSendOut)"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': this.$t(locale.book),
                'modal-class': 'modal-primary',
            }" -->
      <ValidationObserver ref="scheduleForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in scheDuleSendOut"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="index == 0">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-else>
              <v-select
                  v-model="form.value"
                  :options="form.option"
                  :placeholder="$t(form.label)"
                  @input="
                  (e) => {
                    e ? (form.label = e.value) : (form.label = '');
                  }
                "
                  class="resource-selector d-inline-block"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ $t(option.label) }}
                  </div>
                </template>
              </v-select>
            </template>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-sendOut')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="runScheduleSendout"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : 'Send'"
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-sendOut-update"
        :title="$t(locale.sendOutSchedulSendOut)"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': this.$t(locale.book),
                'modal-class': 'modal-primary',
            }" -->
      <ValidationObserver ref="scheduleForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in scheDuleSendOut"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ form.fieldValue }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="index == 0">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-else>
              <v-select
                  v-model="form.value"
                  :options="form.option"
                  :placeholder="$t(form.label)"
                  @input="
                  (e) => {
                    e ? (form.value = e.value) : (form.value = '');
                  }
                "
                  class="resource-selector d-inline-block"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">{{ $t(option.label) }}</div>
                </template>
              </v-select>
            </template>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-sendOut-update')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="runScheduleSendout"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : 'Update'"
            />
          </b-button>
          <b-button
              variant="danger"
              size="md"
              :disabled="sendingModalActionDelete"
              @click="runDeleteSchedule('invoice')"
          >
            <span
                v-text="
                sendingModalActionDelete ? $t('Message.loading') : 'Delete'
              "
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-receipt"
        :title="'Skicka kvitton'"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <ValidationObserver ref="receipt">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in receiptForm"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <!--              <span class="text-danger">*</span>-->
            </label>

            <template>
              <b-form-textarea
                  id="textarea"
                  v-model="form.value"
                  placeholder="Meddelande i mejlet"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
              <small class="text-danger" v-text="errors[0]"/>
            </template>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-receipt')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="runInvoiceReceipt"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : $t('Message.send')"
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-customize"
        :title="
        $t(locale.modalCustomize, {
          pageTitle: $t($route.meta.pageTitle),
        })
      "
        :hideFooter="true"
    >
      <!-- :options="{
                size: 'lg',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
                'ok-title': this.$t(locale.update),
            }" -->
      <div class="scrol-area" style="height: 250px">
        <draggable v-model="invoiceTableHeader">
          <b-card
              v-for="(data, index) in invoiceTableHeader"
              :key="index"
              no-body
              class="border mb-1"
              style="cursor: grab"
          >
            <b-card-text class="p-1 d-flex bigGap">
              <mdicon :width="20" :height="20" name="flip-to-back"/>

              <p class="mb-0" v-text="renderName(data)"/>
            </b-card-text>
          </b-card>
        </draggable>
      </div>

      <div class="pt-1">
        <p class="" v-text="$t(locale.chooseColumn)"/>
        <b-row class="scrol-area">
          <b-col
              cols="12"
              lg="6"
              v-for="(column, index) in Object.keys(INVOICE_CUSTOMIZE)"
              :key="index"
          >
            <div class="mt-1">
              <b-form-checkbox v-model="invoiceTableHeader" :value="column">
                {{ renderName(column) }}
                <!-- {{ INVOICE_CUSTOMIZE[column] }} -->
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex smallgap pt-1 justify-content-end">
        <div>
          <b-button
              variant="primary"
              @click="runCustomization"
              v-text="
              sendingModalAction ? $t('Message.loading') : $t(locale.update)
            "
          />
        </div>
      </div>
    </Modal>
    <Modal
        v-if="accessDenied"
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied :message="accessDeniedMessage"/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import {dateMixins} from "@/mixins/dateMixins";
import useAppConfig from "@core/app-config/useAppConfig";
import debounce from "lodash.debounce";
import accessDenied from "@core/components/permission/accessDenied";
import Paginate from "vuejs-paginate";
import {
  INVOICE_CUSTOMIZATION,
  renderCustomizeName,
} from "./../customization/Constants";
import {mapGetters} from "vuex"

export default {
  name: "invoicesOverview",
  mixins: [dateMixins],
  components: {
    paginate: Paginate,
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    accessDenied
  },

  data() {
    return {
      accessDeniedMessage: null,
      config: useAppConfig(),
      filterType: ['unpaid', 'paid', 'fullypaid', 'unbooked', 'booked', 'voided'],
      search: "",
      totalProducts: "",
      accessDenied: true,
      loadingState: true,
      sendingModalAction: false,
      INVOICE_CUSTOMIZE: {...INVOICE_CUSTOMIZATION},
      invoiceCustomization: [],
      sendingModalActionDelete: false,
      accountResults: [],
      actionLable: "",
      hiddenTable: false,
      loadingAction: false,
      sendOutBulk: false,
      activeLabel: "all",
      selectedData: {},
      items: [],
      bulkScheduled: [],
      alreadyScheduled: [],
      queryParams: {
        // active: "all",
        limit: 50,
        filter: "",
      },
      pageOptions: [50, 100, 150, 200],
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      selected: false,
      checked: "selected",
      categoryValue: "all",
      registerPaymentForm: [
        {
          label: "invoicesCard.paymentDate",
          rules: "required",
          type: "date",
          value: "",
          fieldValue: "payment_date",
        },
        {
          label: "invoicesCard.paymentAmount",
          rules: "required",
          type: "input",
          value: "",
          fieldValue: "amount",
        },
        {
          label: "invoicesCard.account",
          rules: "required",
          type: "select",
          value: "",
          fieldValue: "mode_of_payment_account",
        },
      ],
      receiptForm: [
        {
          label: "invoicesCard.customMessage",
          rules: "required",
          type: "textarea",
          value: "",
          fieldValue: "custom_message",
        },
      ],
      scheDuleSendOut: [
        {
          label: "dateToSend",
          rules: "required",
          type: "date",
          value: "",
          fieldValue: "date_to_send",
        },
        {
          label: "sendChannel",
          rules: "required",
          type: "select",
          option: [
            {
              label: "regular",
              value: "regular",
            },
            {
              label: "noxFinance",
              value: "nox_finans",
            },
          ],
          value: "",
          fieldValue: "send_channel",
        },
        {
          label: "invoicesCard.distributionMethod",
          rules: "required",
          type: "select",
          option: [
            {
              label: "print",
              value: "print",
            },
            {
              label: "einvoice",
              value: "einvoice",
            },
            {
              label: "email",
              value: "email",
            },
          ],
          value: "",
          fieldValue: "send_method",
        },
      ],
      actionButton: [
        {
          label: "invoicesCard.cancel",
          variant: "dark",
          value: "cancel",
        },
        {
          label: "invoicesCard.booked",
          variant: "thirdColor",
          value: "bookkeep",
        },
        {
          label: "invoicesCard.send",
          variant: "dark",
          value: "send",
        },
        {
          label: "invoicesCard.receipt",
          variant: "dark",
          value: "receipt",
        },
        {
          label: "invoicesCard.reminder",
          variant: "dark",
          value: "reminder",
        },
        {
          label: "invoicesCard.email",
          variant: "dark",
          value: "email",
        },
        {
          label: "invoicesCard.einvoice",
          variant: "dark",
          value: "einvoice",
        },
        // {
        //     label: 'invoicesCard.schedule',
        //     variant: 'dark',
        //     value: 'schedule',
        // },
      ],
      categoryButton: [
        {
          label: "invoicesCard.all",
          variant: "indigo",
          value: "all",
        },
        {
          label: "invoicesCard.booked",
          variant: "thirdColor",
          value: "booked",
        },
        {
          label: "invoicesCard.notBooked",
          variant: "warning",
          value: "not-booked",
        },
        {
          label: "invoicesCard.unpaid",
          variant: "danger",
          value: "unpaid",
        },
        {
          label: "invoicesCard.fullPaid",
          variant: "success",
          value: "fullypaid",
        },
        {
          label: "invoicesCard.voided",
          variant: "dark",
          value: "void",
        },
      ],

      fields: [
        {
          key: "checkbox",
          tdClass: "bTableMainThStyle",
          thStyle: {width: "20px", padding: "0.72rem 10px"},
        },
        {
          key: "id",
          label: "invoicesCard.table.number",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "70px",
          },
        },
        {
          key: "ocr",
          label: "invoicesCard.table.ocr",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
        {
          key: "customer_number",
          label: "CustomersCard.table.custNumber",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "128px",
          },
        },
        {
          key: "customer_name",
          label: "CustomersCard.table.name",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "80px",
          },
        },
        {
          key: "invoice_date",
          label: "dashboardCard.table.invoiceDateHead",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "100px",
          },
        },
        {
          key: "total",
          label: "HomeCard.Total",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
        {
          key: "balance",
          label: "invoicesCard.table.balance",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "currency",
          label: "InvoiceCardCustomerEdit.currency",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "75px",
          },
        },

        {
          key: "due_date",
          label: "dashboardCard.table.dueDateHead",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "90px",
          },
        },
        {
          key: "final_pay_date",
          label: "invoicesCard.table.fullPayment",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "90px",
          },
        },
        {
          key: "sent",
          label: "invoicesCard.table.sent",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },

        {
          key: "action",
          label: "CustomersCard.table.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
      ],

      sendInvoice: [
        {
          label: "invoicesCard.invoiceType",
          rules: "required",
          value: "",
          visible: true,
          options: [
            {
              label: "Fortnox Fakturaservice",
              value: "fortnox",
            },
            {
              label: "Regular Invoice",
              value: "regular",
            },
          ],
          fieldValue: "type",
        },
        {
          label: "invoicesCard.sendMethod",
          rules: "required",
          value: "",
          visible: false,
          selectOptions: [],
          options: {
            regular: [
              {
                label: "Email",
                value: "email",
              },
              {
                label: "Einvoice",
                value: "EINVOICE",
              },
            ],
            fortnox: [
              {
                label: "Letter",
                value: "LETTER",
              },
              {
                label: "Email",
                value: "email",
              },
              {
                label: "None",
                value: "NONE",
              },
              {
                label: "Einvoice",
                value: "EINVOICE",
              },
            ],
          },
          fieldValue: "action",
        },
      ],
      services: "LEDGERBASE",
      locale: {
        registerPayment: "invoicesCard.registerPayment",
        book: "invoicesCard.book",
        inputPlaceholder: "invoiceCard.searchLabel",
        showPerPage: "golfRelatedCard.showPerPage",
        tag: "CustomersCard.tag",
        sendOutSchedulSendOut: "invoicesCard.sendOutSchedulSendOut",
        apply: "golfRelatedCard.apply",
        action: "golfRelatedCard.action",
        send: "invoicesCard.send",
        sendOut: "invoicesCard.sendOut",
        sendReceipt: "invoicesCard.sendReceipt",
        copy: "InvoiceCardCustomerEdit.copy",
        sendMethod: "invoicesCard.sendMethod",
        customize: "CustomersCard.customize",
        modalCustomize: "customizeColumn",
        update: "UsersCard.update",
      },
      invoiceTableHeader: [],
    };
  },

  watch: {
    "queryParams.search": {
      handler(value) {
        this.queryParams.search = value;
        this.getInvoices();
      },
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    features() {
      return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    closeModal() {
    },
    performAllChecked(e) {
      this.items.forEach((element) => {
        element.checkbox = this.selected === "selected" ? true : false;
      });
    },
    performSingleChecked(e) {
    },
    inputChange(value) {
      if (value) {
        this.sendInvoice[1].visible = true;
        this.sendInvoice[1].selectOptions =
            this.sendInvoice[1].options[value.value];
      } else this.sendInvoice[1].visible = false;
    },

    okEvent(event) {
      event.preventDefault();
    },
    switchLimit(value) {
      this.queryParams.limit = value;
      this.getInvoices();
    },
    moveComponent() {
      this.$emit("moveComponent", "createInvoices");
    },
    selectedRow(value) {
      this.$router.push({
        name: "acountingEditInvoices",
        params: {id: value.uuid},
      });
    },

    selectedStatus(buttonValue) {
      const {value} = buttonValue;
      this.categoryValue = value;
      if (this.search == "") delete this.queryParams.search;

      let payload = "";

      if (value === "unpaid") {
        this.queryParams.filter = "unpaid";
        // this.queryParams["booked"] = 1;
        // this.queryParams["cancelled"] = 0;
        // payload = "booked=1&fully_paid=0&cancelled=0";
      } else if (value === "paid") {
        delete this.queryParams.booked;
        this.queryParams.filter = "paid";

        // this.queryParams["fully_paid"] = 1;
        // this.queryParams["cancelled"] = 0;
        // payload = "fully_paid=1&cancelled=0";
      } else if (value === "all") {
        // delete this.queryParams.booked;
        this.queryParams.filter = "all";
      } else if (value === "fullypaid") {
        this.queryParams.filter = "fullypaid";
      } else if (value === "void") {
        this.queryParams.filter = "voided";
      } else if (value === "booked") {
        this.queryParams.filter = "booked";
      } else if (value === "not-booked") {
        this.queryParams.filter = "unbooked";
      } else {
        this.queryParams.filter = "booked";
      }

      delete this.queryParams.limit;

      this.getInvoices();
    },
    renderName(key) {
      return renderCustomizeName(this.getLang, key, INVOICE_CUSTOMIZATION);
    },
    getCustomizeHeader() {
      this.$useJwt
          .customization({URL: "invoice", method: "get"})
          .then((res) => {
            const fields = [
              {
                key: "checkbox",
                label: "articleCard.table.articleNumber",
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "50px",
                },
              },
              {
                key: "action",
                label: "CustomersCard.table.action",
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "100px",
                },
              },
            ];
            if (res.data.data.length > 0) {
              this.invoiceTableHeader = res.data.data;
              res.data.data.map((el, i) => {
                const obj = {
                  key: el,
                  label: el,
                  tdClass: "bTableThStyle",
                  thStyle: {
                    padding: "0.72rem 10px",
                    width: "140px",
                  },
                };
                fields.splice(parseInt(i + 1), 0, obj);
              });
            } else {
              Object.keys(CUSTOMER_CUSTOMIZATION).forEach((el) => {
                this.invoiceTableHeader.push(el);
              });
            }
            this.fields = fields;
          });
    },
    runCustomization() {
      if (this.invoiceTableHeader.length > 0) {
        this.sendingModalAction = true;
        const payload = {
          columns: this.invoiceTableHeader,
        };
        this.$useJwt
            .customization({URL: "invoice", method: "put"}, payload)
            .then((res) => {
              this.getCustomizeHeader();
              this.sendingModalAction = false;
              this.getInvoices();
              this.$bvModal.hide("modal-customize");
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data.message,
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    firePagination(number) {
      this.pagination.currentPage = number;
      this.queryParams.page = number;
      this.getInvoices();
    },
    getInvoices() {
      this.loadingState = true;
      this.$useJwt
          .getInvoices({URL: "", method: "get"}, {params: this.queryParams})
          .then((res) => {
            const {data} = res.data;

            this.loadingState = false;
            this.hiddenTable = true;

            this.pagination = {
              totalProducts: res.data.total_pages,
            };

            this.queryParams.limit = res.data.limit;
            this.items = data.map((el) => {
              return {
                ...el,
                checkbox: false,
              };
            });
          }).catch(err => {
        this.loadingState = false;
        this.hiddenTable = true;
        if (err.response.status === 403) {
          this.accessDeniedMessage = err.response.data.message
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      });
    },
    runScheduleSendout() {
      this.$refs.scheduleForm.validate().then((res) => {
        if (res) {
          const payload = {};
          this.scheDuleSendOut.map((el) => {
            payload[el.fieldValue] = el.value;
          });

          if (this.sendOutBulk) {
            // Will leave this part since current api doesn't allow bulk schedule
            const filter = this.items.filter((el) => el.checkbox);
            if (filter.length > 0) {
              const array = [];
              const document = [];
              filter.map((el) => {
                array.push(el.uuid);
                document.push(el.document_number);
              });
              payload["invoices"] = array;
              payload["document_number"] = document;
            }
          } else {
            payload["invoices"] = [this.selectedData.uuid];
            payload["document_number"] = this.selectedData.document_number;
          }

          const mode = {URL: "invoice_sendout/", method: "post"};

          const validateDate = this.validatePresentDate(
              payload["date_to_send"]
          );

          if (validateDate) {
            this.sendingModalAction = true;

            this.$useJwt
                .runScheduleCommand(mode, payload)
                .then((res) => {
                  this.scheDuleSendOut.map((el) => {
                    el.value = "";
                  });
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.message],
                      "CheckIcon",
                      "success"
                  );
                  this.$bvModal.hide("modal-sendOut");
                })
                .catch((err) => {
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                });
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Date is in the past",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
    registerPaymentInvoice() {
      this.$refs.registerForm.validate().then((res) => {
        if (res) {
          const payload = {};
          this.registerPaymentForm.map((el) => {
            payload[el.fieldValue] = el.value;
          });

          if (parseFloat(payload["amount"])) {
            payload["invoice_number"] = this.selectedData.document_number;
            const mode = {URL: "/", method: "post"};

            // const validateDate = this.validatePresentDate(
            //     payload["date_to_send"]
            // );

            // if (validateDate) {
            this.sendingModalAction = true;

            this.$useJwt
                .getInvoicePayment(mode, payload)
                .then((res) => {
                  this.registerPaymentForm.map((el) => {
                    el.value = "";
                  });
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.message],
                      "CheckIcon",
                      "success"
                  );
                  this.$bvModal.hide("modal-registerPayment");
                })
                .catch((err) => {
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.message,
                      "AlertTriangleIcon",
                      "danger"
                  );
                });
            // } else {
            //   this.popupMsg(
            //       this.$t("Message.Failed"),
            //       "Date is in the past",
            //       "AlertTriangleIcon",
            //       "danger"
            //   );
            // }
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Please input a valid amount",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
    sendInvoicePayment() {
      this.$refs.sendForm.validate().then((res) => {
        if (res) {
          const payload = {};
          this.sendInvoice.map((el) => {
            payload[el.fieldValue] = el.value.value;
          });
          const mode = {URL: "", method: "put"};

          const filter = this.items.filter((el) => el.checkbox);
          if (filter.length > 0) {
            if (payload["type"] === "regular") {
              payload["invoices"] = filter.map((el) => el.uuid);
              mode.URL = "/bulkactions";
            } else {
              let action = payload["action"].toUpperCase();
              mode.URL = `/noxfinansinvoices/bulk/send_invoice`;
              mode.method = "put";
              payload["action"] = action;
              payload["invoices"] = []
              filter.map((el) => {
                payload['invoices'].push(el.uuid)
              });
              payload["send_method"] = action;
              payload["service"] = this.services;
            }
          } else {
            if (payload["type"] === "regular") {
              payload["invoices"] = [this.selectedData.uuid];
              mode.URL = "/bulkactions";
            } else {
              mode.URL = "/noxfinansinvoices/send_invoice/";
              mode.method = "post";
              payload["invoice_number"] = this.selectedData.document_number;
              payload["send_method"] = payload["action"];
              payload["service"] = this.services;
            }
          }

          this.sendingModalAction = true;

          this.$useJwt
              .getInvoices(mode, payload)
              .then((res) => {

                this.sendingModalAction = false;
                if (res.data.hasOwnProperty('failed')) {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      "Databasen är inte aktiverad för Fortnox Finans",
                      "AlertTriangleIcon",
                      "danger"
                  );
                } else {
                  this.sendInvoice.map((el) => {
                    el.value = "";
                  });
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.message],
                      "CheckIcon",
                      "success"
                  );
                  this.$bvModal.hide("modal-send-payment");
                }


              })
              .catch((err) => {
                this.sendingModalAction = false;
                if (err.response.data.hasOwnProperty('message') && err.response.data.hasOwnProperty('eng_message') === false) {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.message,
                      "AlertTriangleIcon",
                      "danger"
                  );
                } else {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.detail,
                      "AlertTriangleIcon",
                      "danger"
                  );
                }

              });
        }
      });
    },
    runInvoiceReceipt() {
      this.$refs.receipt.validate().then((res) => {
        if (res) {
          const payload = {};
          this.receiptForm.map((el) => {
            payload[el.fieldValue] = el.value;
          });
          payload["invoice_uuids"] = [this.selectedData.uuid];
          const mode = {URL: "send_receipts", method: "post"};
          this.sendingModalAction = true;

          this.$useJwt
              .customBaseURLOnly(mode, payload)
              .then((res) => {
                this.getInvoices();
                this.receiptForm.map((el) => {
                  el.value = "";
                });
                this.sendingModalAction = false;
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
                this.$bvModal.hide("modal-receipt");
              })
              .catch((err) => {
                this.sendingModalAction = false;
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data[this.message],
                    "AlertTriangleIcon",
                    "danger"
                );
              });
        }
      });
    },
    openRceiptForm(data) {
      this.selectedData = data;
      this.$bvModal.show("modal-receipt");
    },
    openModal(data, mode) {
      this.selectedData = data;
      if (mode === "invoice") {
        this.$bvModal.show("modal-send-payment");
      } else if (mode === "register") {
        this.$bvModal.show("modal-registerPayment");
      } else if (mode === "sendout") {
        this.$bvModal.show("modal-sendOut");
      }
      //  else if (mode === 'sendout') {
      //     this.$bvModal.show('modal-sendOut')
      // }
    },
    openUpdateModal(data, mode) {
      this.selectedData = data;
      if (mode === "sendout") {
        this.scheDuleSendOut.map((el) => {
          if (data.scheduled_invoice_sending[0][el.fieldValue]) {
            el.value = data.scheduled_invoice_sending[0][el.fieldValue];
          }
        });
        this.$bvModal.show("modal-sendOut-update");
      }
      //  else if (mode === 'register'){
      //     this.$bvModal.show('modal-registerPayment')
      // } else if (mode === 'sendout') {
      //     this.$bvModal.show('modal-sendOut')
      // }
    },
    clearForm() {
      this.scheDuleSendOut.map((el) => {
        el.value = "";
      });
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt
          .getAllRegisters(`accounts?search=${escape(search)}`)
          .then((res) => {
            this.accountResults = res.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    changeAccounts(account) {
      this.registerPaymentForm[2].value = account.number;
    },
    applyMultiAction(value) {
      if (value) {
        const payload = {
          invoices: [],
          action: value,
        };
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          if (value !== "send") {
            if (value === "schedule") {
              this.bulkScheduled = [];
              this.alreadyScheduled = [];

              // get checked invoices
              filter.map((el) => {
                // schedule invoice sendout only for !cancelled and !fully_paid
                if (!el.cancelled && !el.fully_paid) {
                  // if the element is not already scheduled, then add to bulkSchedule or else add to alreadyScheduled
                  if (!el.scheduled_invoice_sending.length) {
                    this.bulkScheduled.push(parseInt(el.document_number));
                  } else {
                    this.alreadyScheduled.push(element);
                  }
                }
              });

              if (!this.bulkScheduled.length) {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    "invalid_row_selection_invoice",
                    "AlertTriangleIcon",
                    "danger"
                );
                return;
              }
              // check if the selection contains any alreadyScheduled rows
              if (this.alreadyScheduled.length) {
                // this.openAlreadyScheduledModal = true;
                // This side will be   complete
              } else {
                this.sendOutBulk = true;
                this.$bvModal.show("modal-sendOut");
              }
              return;
            } else {
              this.loadingAction = true;
              const array = [];
              filter.map(element => {
                array.push(element.uuid);
              });

              payload["invoices"] = array;
              payload["action"] = value;

              this.$useJwt
                  .getInvoices({URL: "/bulkactions", method: "put"}, payload)
                  .then((res) => {
                    this.actionLable = "";
                    this.loadingAction = false;
                    this.getInvoices();
                    this.popupMsg(
                        this.$t("Message.Success"),
                        res.data[this.locale_message],
                        "CheckIcon",
                        "success"
                    );
                  })
                  .catch((err) => {
                    this.loadingAction = false;
                    this.popupMsg(
                        this.$t("Message.Failed"),
                        err.response.data[this.locale_message],
                        "AlertTriangleIcon",
                        "danger"
                    );
                  });
            }
          } else {
            if (value === "send") {
              this.$bvModal.show("modal-send-payment");
            }
          }
        }
      }
    },
    runDeleteSchedule(mode) {
      this.sendingModalActionDelete = true;
      const payload = {URL: "", method: "delete"};
      if (mode === "invoice") {
        payload.URL = `schedules/invoice_sendout/${this.selectedData.scheduled_invoice_sending[0].uuid}`;
      }

      this.$useJwt
          .customBaseURLOnly(payload)
          .then((res) => {
            this.getInvoices();
            if (mode === "invoice") {
              this.$bvModal.hide("modal-sendOut-update");
            }
            this.sendingModalActionDelete = false;
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.sendingModalActionDelete = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    runSchedSuleSendout() {
      this.$refs.scheduleForm.validate().then((res) => {
        if (res) {
          const payload = {};
          this.scheDuleSendOut.map((el) => {
            payload[el.fieldValue] = el.value;
          });

          payload["invoices"] = [this.selectedData.uuid];
          payload["document_number"] = this.selectedData.document_number;

          const mode = {
            URL: `invoice_sendout/${this.selectedData.scheduled_invoice_sending[0].uuid}`,
            method: "put",
          };

          const validateDate = this.validatePresentDate(
              payload["date_to_send"]
          );

          if (validateDate) {
            this.sendingModalAction = true;

            this.$useJwt
                .runScheduleCommand(mode, payload)
                .then((res) => {
                  this.getInvoices();
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.locale_message],
                      "CheckIcon",
                      "success"
                  );
                  this.$bvModal.hide("modal-sendOut-update");
                })
                .catch((err) => {
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.locale_message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                });
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Date is in the past",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
    sortColumn(item) {
      this.queryParams.ordering = item;
      delete this.queryParams.search;
      this.getInvoices();
    },
    copyInvoiceData(data) {
      window.localStorage.setItem("__invoiceD__", JSON.stringify(data));
      this.$router.push({name: "acountingCreateInvoices"});
    },
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getCustomizeHeader();
    this.getInvoices();
    // this.$useJwt.getInvoicePayment().then(res => {
    // });
  },
};
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.scrol-area {
  overflow: auto;
  scrollbar-color: $primary transparent;
}

.resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";

.table th {
  padding: 0.4rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  padding: 0.4rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
