export const CONTRACT_CUSTOMIZATION = {
    document_number : 'Avtalsnumer',
    customer_number : 'Kundnummer',
    contract_length : 'Avtalslängd',
    invoice_interval : 'Fakturaintervall',
    last_invoice_date : 'Senast fakturerad',
    invoices_remaining :  'Fakturor kvar' ,
    period_start :  'Periodstart',
    period_end : 'Periodslut' ,
    total :  'Fakturavärde',
    category : 'Avtalskategori (status)',
    // tags :  'Taggar' ,
    continuous :  'Löpande',
    cost_center : 'Kostnadsställe',
    contract_date : 'Avtalsdatum',
    currency :  'Valuta' ,
    customer_name :  'Kundnamn',
    freight :  'Frakt',
    net  : 'Fakturavärde exkl. moms',
    our_reference :   'Vår referens',
    project :  'Projekt',
    terms_of_payment  :  'Betalningsvillkor',
    total_vat :  'Momsbelopp' ,
    vat_included  :  'Moms inkluderat' ,
    your_order_number :  'Ert ordernummer',
    your_reference :   'Er referens',
    exclude_from_auto_renewal : 'Exkludera från avtal-autoförnyelse',
    upcoming : 'Kommande',
}

export const CONTRACT_DEFAULT = {
    document_number : 'Avtalsnummer',
    customer_number : 'Kundnumer',
    contract_length : 'Avtalslängd',
    invoice_interval : 'Fakturaintervall',
    last_invoice_date : 'Senast fakturerad',
    invoices_remaining :  'Fakturor kvar' ,
    period_start :  'Periodstart',
    period_end : 'Periodslut' ,
    total :  'Fakturavärde',
    category : 'Avtalskategori (status)',
}

export const CUSTOMER_CUSTOMIZATION = {
    customer_number : 'Kundnummer',
    name : 'Namn',
    organisation_number : 'Organisations- /personnummer',
    zip_code : 'Postnummer',
    city : 'Stad',
    country : 'Land',
    email : 'Mejladress',
    phone1  : 'Telefonnummer',
    active : 'Status',
    address : 'Address',
    // tags : 'Taggar',
    cost_center : 'Kostnadsställe',
    currency : 'Valuta',
    delivery_address1 : 'Leveransadress 1',
    delivery_address2 : 'Leveransadress 2',
    delivery_city  : 'Stad (Leverans)',
    delivery_country : 'Land (Leverans)',
    delivery_name  : 'Namn (Leverans)',
    delivery_phone1 : 'Telefonnummer 1 (Leverans)',
    delivery_phone2 : 'Telefonnummer 2 (Leverans)',
    delivery_zip_code : 'Postnummer (Leverans)',
    email_invoice : 'Fakturamejladress',
    email_offer : 'Offertmejladress',
    email_order : 'Ordermejladress',
    invoice_discount  :  'Fakturarabatt',
    our_reference : 'Vår referens',
    external_reference : 'Extern referens',
    your_reference :  'Er referens',
    phone2 : 'Telefonnummer 2' ,
    price_list : 'Prislista' ,
    project :  'Project',
    terms_of_payment : 'Betalningsvillkor',
    vat_number : 'VAT-nummer' ,
    visiting_address : 'Besöksadress',
    visiting_city : 'Stad (Besök)',
    visiting_country : 'Land (Besök)',
    visiting_zip_code : 'Postnummer (besök)',
    segment : 'Segment',
}

export const CUSTOMER_DEFAULT = {
    customer_number : 'Kundnummer',
    name : 'Namn',
    organisation_number : 'Organisations-/personnummer',
    zip_code : 'Postnummer',
    city : 'Stad',
    country : 'Land',
    email : 'Mejladress',
    phone1  : 'Telefonnummer 1',
    active : 'Status',
}

export const INVOICE_CUSTOMIZATION = {

    document_number : 'Fakturanummer',
    ocr : 'OCR',
    customer_number : 'Kundnummer',
    customer_name : 'Namn',
    invoice_date : 'Fakturadatum',
    total : 'Totalt',
    balance : 'Balans',
    currency : 'Valuta',
    due_date : 'Förfallodatum',
    fully_paid : 'Slutbetald',
    sent : 'Skickad',
    address : 'Adress',
    booked : 'Bokförd',
    cancelled : 'Makulerad',
    city : 'Stad',
    contract_reference : 'Avtalsreferens',
    cost_center : 'Kostnadsställe',
    country : 'Land',
    credit : 'Kreditfaktura',
    credit_invoice_reference : 'Kreditfakturareferens',
    currency_rate : 'Valutakurs',
    currency_unit : 'Valutaenhet',
    delivery_address1 : 'Leveransadress 1',
    delivery_address2 : 'Leveransadress 2',
    delivery_city : 'Stad (Leverans)',
    delivery_country : 'Land (Leverans)',
    delivery_date : 'Leveransdatum',
    delivery_name  : 'Namn (Leverans)',
    delivery_zip_code : 'Postnummer (Leverans)',
    final_pay_date : 'Slutbetalningsdatum',
    freight  : 'Frakt',
    invoice_period_end : 'Periodslut',
    invoice_period_start : 'Periodstart',
    invoice_type : 'Fakturatyp',
    net : 'Fakturavärde exkl. moms',
    nox_finans : 'Skickad via Fortnox Fakturaservice',
    offer_reference : 'Offertreferens',
    order_reference : 'Orderreferens',
    price_list : 'Prislista',
    project : 'Projekt',
    terms_of_payment : 'Betalningsvillkor',
    total_vat : 'Momsbelopp',
    vat_included : 'Inklusive moms',
    voucher_number : 'Verifikationsnummer',
    voucher_series : 'Verifikationsserie',
    your_order_number  : 'Ert ordernummer',
    your_reference : 'Er referens',
    zip_code : 'Postnummer',
    receipt_sent: 'Kvitto skickat',
    reminder_sent: 'Påminnelse skickad'
}

export const INVOICE_DEFAULT = {
    document_number : 'Fakturanummer',
    ocr : 'OCR',
    customer_number : 'Kundnummer',
    customer_name : 'Namn',
    invoice_date : 'Fakturadatum',
    total : 'Totalt',
    balance : 'Balans',
    currency : 'Valuta',
    due_date : 'Förfallodatum',
    fully_paid : 'Slutbetald',
    sent : 'Skickad',
}

export const ARTICLE_CUSTOMIZATION = {
    article_number : 'Artikelnummer',
    description : 'Beskrivning',
    sales_price : 'Försäljningspris',
    purchase_price : 'Inköpspris',
    quantity_in_stock : 'Antal i lager',
    reserved_quantity : 'Reserverat antal',
    stock_place : 'Lagerplats',
    stock_value : 'Lagervärde',
    // tags : 'Taggar',
    bulky : 'Bulkig',
    disposable_quantity  : 'Disponibelt antal',
    ean : 'EAN',
    house_work : 'Husarbete',
    manufacturer : 'Tillverkare',
    manufacturer_article_number : 'Tillverkarens artikelnummer',
    stock_goods : 'Lagervara',
    supplier_name : 'Leverantörsnamn',
    supplier_number : 'Leverantörsnummer',
    type : 'Artikeltyp',
    unit : 'Enhet',
    vat : 'Moms',
    webshop_article : 'Extern webbutik',
    expired : 'Utgången',
    freight_cost : 'Fraktkostnad',
    segment : 'Segment',
    status : 'Status',
    active: 'Active'
}

export const ARTICLE_DEFAULT = {
    article_number : 'Artikelnummer',
    description : 'Beskrivning',
    sales_price : 'Försäljningspris',
    purchase_price : 'Inköpspris',
    quantity_in_stock : 'Antal i lager',
    reserved_quantity : 'Reserverat antal',
    stock_place : 'Lagerplats',
    stock_value : 'Lagervärde',
}

export default {
    CONTRACT_CUSTOMIZATION,
    CONTRACT_DEFAULT,
    CUSTOMER_CUSTOMIZATION,
    CUSTOMER_DEFAULT,
    INVOICE_CUSTOMIZATION,
    INVOICE_DEFAULT,
    ARTICLE_CUSTOMIZATION,
    ARTICLE_DEFAULT
}


export const renderCustomizeName = (lang, key, MAIN) => {
    if (lang !== '' && lang === 'en') {
        const name = Object.keys(MAIN).filter(el => el === key)
        if (name.length > 0) {
            return name[0].split('_').join(' ') 
        }
    } else {
        return MAIN[key]
    }
}